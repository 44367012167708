/* eslint-disable */
/* prettier-ignore */
import React from 'react';
import { Link } from 'gatsby';


import Layout from '@/components/Layout';
import SEO from '@/components/SEO';

import NotFoundIcon from '@/images/paginas/404/not-found-icon.svg';

import {
  PageContainer,
  PageWrapper,
  Section,
  SectionBottom,
  SectionTop,
  SectionWrapper,
} from '@/styles/pages/404';

const NotFoundPage = (): JSX.Element => {
  return (
    <>
      <SEO
        pageTitle="404 - Página não encontrada"
        title="404 - Página não encontrada"
        description="Página de erro"
      />
      <Layout>
        <PageWrapper>
          <PageContainer>
            <SectionWrapper>
              <Section>
                <SectionTop>
                  <img
                    src={NotFoundIcon}
                    alt="Ícone indicando página não encontrada"
                  />
                  <h2>Página não encontrada</h2>
                  <p>
                    A página que você solicitou pode ter sido removida ou
                    excluída.
                  </p>
                </SectionTop>
                <SectionBottom>
                  <p>Talvez seja útil:</p>
                  <ul>
                    <li>
                      <Link to="/">Página inicial</Link>
                    </li>
                    <li>
                      <a
                        href="https://cliente.generoso.com.br/coleta/coletar"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Agendar coleta
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://cliente.generoso.com.br/cotacao/cotar"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Cotação
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://cliente.generoso.com.br/rastrear"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Rastreamento
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://cliente.generoso.com.br/prazos"
                        >
                        Calcular previsão de entrega
                      </a>
                    </li>
                    <li>
                      <Link
                        target="_blank"
                        to="https://cliente.generoso.com.br/faturas"
                      >
                        2ª via de boleto
                      </Link>
                    </li>
                    <li>
                      <Link to="/atendimento">Atendimento</Link>
                    </li>
                    <li>
                     <a href="https://cliente.generoso.com.br/unidades" target='_blank' rel="noreferrer">Unidades</a>
                    </li>
                    <li>
                      <Link to="/area-de-atuacao">Área de atuação</Link>
                    </li>
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                  </ul>
                </SectionBottom>
              </Section>
            </SectionWrapper>
          </PageContainer>
        </PageWrapper>
      </Layout>
    </>
  );
};

export default NotFoundPage;

import styled from 'styled-components';
import { colors } from '../GlobalStyle';

export const PageWrapper = styled.div`
  width: 100%;
  max-width: 100vw;

  height: 100%;

  display: flex;
  justify-content: center;
`;

export const PageContainer = styled.div`
  width: 100%;
  max-width: 1440px;

  height: fit-content;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SectionWrapper = styled.div`
  width: 100%;
  max-width: 1380px;

  height: fit-content;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 449px;

  margin-top: 97px;
  margin-bottom: 127px;

  @media (max-width: 1440px) {
    margin-left: 29px;
    margin-right: 29px;
  }

  @media (max-width: 500px) {
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const SectionTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  h2 {
    font-size: 16px;
    color: ${colors.red};

    margin-top: 27px;
  }

  p {
    font-size: 14px;

    margin-top: 8px;
  }
`;

export const SectionBottom = styled.div`
  width: 100%;
  max-width: 200px;

  border: 1px solid ${colors.gray300};
  border-radius: 16.8px;

  box-sizing: border-box;

  padding: 26px 24px 25px 24px;

  position: relative;

  margin-top: 30px;

  p {
    font-size: 14px;

    display: flex;
    justify-content: center;

    margin-left: -8px;

    width: 120px;

    position: absolute;
    top: -9px;

    background-color: white;
  }

  li {
    margin-top: 7px;

    &:first-child {
      margin-top: 0;
    }
  }

  a {
    color: ${colors.blue300};

    line-height: 18px;

    font-size: 14px;

    &:hover {
      color: ${colors.blue400};
    }
  }
`;
